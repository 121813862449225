import { combineReducers } from "redux";

import { userReducer } from "./user";
import { commentsReducer } from "./comments";
import { gameReducer } from "./game";

export default combineReducers({
  user: userReducer,
  comments: commentsReducer,
  game: gameReducer,
});
