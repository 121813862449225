import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";

let twitches = [];

async function getChannels(query) {
  try {
    const auth_url = 'https://id.twitch.tv/oauth2/token?client_id=djwykg5xehjf7o506ry7dbbc4jatq2&client_secret=iebcaomvtu5b5mpf85n2d5hly7ox86&grant_type=client_credentials';
    const auth_res = await axios.post(auth_url);

    const api = axios.create({
      headers: {
        'Client-ID' : 'djwykg5xehjf7o506ry7dbbc4jatq2',
        'Authorization' : `Bearer ${auth_res.data.access_token}`,
      }
    });
    const game_res = await api.get(`https://api.twitch.tv/helix/games?name=${query}`);

    if (game_res.data.data.length) {
      const params = {
        game_id: game_res.data.data[0].id,
        first: 5,
      };
      const stream_res = await api.get('https://api.twitch.tv/helix/streams', { params });
      return stream_res.data.data;
    } else {
      return [];
    }
  } catch (err) {
    console.error(err);
  }
}

function Streamers({ game }) {
  const [isEmpty, setIsEmpty] = useState(false);
  const maxChannels = 4;
  useEffect(() => {
    getChannels(game.name)
      .then((channels) => {
        if (channels.length === 0) {
          setIsEmpty(true);
        } else {
          channels.slice(0, maxChannels).forEach((channel, index) => {
            const options = {
              width: 400,
              height: 300,
              channel: channel.user_login,
            };
            twitches.push(new Twitch.Player(`twitch-embed-${index + 1}`, options));
          });
        }
      })
      .catch((error) => {
        console.error("Could not get streams", error);
        setIsEmpty(true);
      });
  }, []); // only once
  if (isEmpty) {
    return (
      <div>
        <h2>
          Sorry, there are no live streams available for {game.name} right now. Check back soon!
        </h2>
      </div>
    );
  } else {
    return (
      <div>
        {Array(4)
          .fill(null)
          .map((_, index) => (
            <div key={index} className="twitch-video" id={`twitch-embed-${index + 1}`}></div>
          ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { game: state.game };
}
export default connect(mapStateToProps)(Streamers);
