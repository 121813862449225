import { connect } from "react-redux";
import * as React from "react";
import { useState, useContext, useRef, useEffect } from "react";

import { Context } from "../context";
import { humanizeDate } from "../datetimes";
import {
  cancelReply,
  newComment,
  likeComment,
  deleteComment,
  unlikeComment,
  selectComments,
  startReply,
} from "../redux/comments";
import { signInByPopup } from "../redux/user";

const GA_LIKE_COMMENT = "Like comment";
const GA_UNLIKE_COMMENT = "Unlike comment";
const GA_START_COMMENT_REPLY = "Start comment reply";
const GA_CANCEL_COMMENT = "Cancel comment";
const GA_DELETE_COMMENT = "Delete comment";
const GA_SUBMIT_COMMENT = "Submit comment";
const GA_SIGNED_IN = "Signed in";
const GA_NOT_SIGNED_IN = "Not signed in";

// https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
function hashString(value, size = 256) {
  let hash = 0;
  let chr;
  for (let i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // convert to 32 bit integer
  }
  return hash % size;
}

function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

function recordEvent(action, label, value) {
  gtag("event", action, { event_category: "comments", event_label: label, value });
}

const Comment = ({ comment, doComment, doUnlike, doLike, doReply, doCancel, doDelete }) => {
  const { theme } = useContext(Context);

  function handleClickUnlike(event) {
    doUnlike(comment.uid);
  }

  function handleClickLike(event) {
    doLike(comment.uid);
  }
  function handleClickReply(event) {
    doReply(comment.uid);
  }
  function handleClickDelete(event) {
    doDelete(comment.uid);
  }

  const avatarHue = hashString(comment.user_uid);
  const avatarSaturation = "80%";
  const avatarLightness = "60%";
  const avatarColor = `hsl(${avatarHue}, ${avatarSaturation}, ${avatarLightness})`;

  return (
    <div className="comment">
      <style jsx>{`
        .comment {
          display: flex;
          width: 100%;
          border-bottom: 1px solid ${theme.borderColor};
        }
        .main {
          width: 100%;
        }
        .avatar {
          line-height: 1.5rem;
          height: 3.5rem;
          width: 3.5rem;
          text-align: center;
          font-size: 1.5rem;
          margin: 1rem 1rem 1rem 0;
          padding: 1rem;

          background: ${avatarColor};
          border-radius: 50%;
          color: #fff;
        }
        .name {
          font-weight: bold;
        }
        .time {
          color: ${theme.tertiaryTextColor};
        }
        .top {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin: 0.5rem 0;
        }
        .content {
          margin: 1rem 0;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
          hyphens: auto;
        }
        .bottom {
          margin: 0.5rem 0;
          color: ${theme.tertiaryTextColor};
        }
        .tools {
          user-select: none;
        }
        .like-action {
          margin-left: 0.5rem;
          cursor: pointer;
        }
        .reply-link {
          cursor: pointer;
        }
        .like-count {
          margin-left: 1rem;
        }
        .delete-link {
          margin-left: 1rem;
          cursor: pointer;
        }
        .action:hover {
          color: ${theme.primaryTextColor};
        }
        .sub-comments {
          margin-left: -4.25rem;
        }
        @media screen and (min-width: 800px) {
          .sub-comments {
            margin-left: 0;
          }
        }
      `}</style>
      <div className="avatar">{comment.name[0]}</div>
      <div className="main">
        <div className="top">
          <div className="name">{comment.name}</div>
          <div className="time">{humanizeDate(new Date(comment.created))}</div>
        </div>
        <div className="content">{comment.content}</div>
        <div className="bottom">
          <div className="tools">
            {comment.level < 3 && (
              <span onClick={handleClickReply} className="action reply-link">
                Reply
              </span>
            )}
            <span className="like-count">{comment.likes}</span>
            {comment.liked ? (
              <span onClick={handleClickUnlike} className="action like-action">
                Unlike
              </span>
            ) : (
              <span onClick={handleClickLike} className="action like-action">
                Like
              </span>
            )}
            {comment.canDelete && comment.leaf && (
              <span onClick={handleClickDelete} className="action delete-link">
                Delete
              </span>
            )}
          </div>
        </div>
        {comment.replying && (
          <CommentForm
            doComment={doComment}
            doCancel={doCancel}
            parentName={comment.name}
            parent_uid={comment.uid}
          />
        )}
        <div className="sub-comments">
          {comment.comments.map((comment) => (
            <Comment
              key={comment.uid}
              comment={comment}
              doComment={doComment}
              doCancel={doCancel}
              doLike={doLike}
              doUnlike={doUnlike}
              doCancel={doCancel}
              doReply={doReply}
              doDelete={doDelete}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const CommentForm = ({ doComment, doCancel, parent_uid, parentName }) => {
  const MAX_CHARACTERS = 1000;
  const MIN_CHARACTERS = 1;
  const { theme } = useContext(Context);
  const [content, setContent] = useState("");
  const [error, setError] = useState("");
  const inputRef = useRef();
  useEffect(() => {
    if (parent_uid) {
      inputRef.current.focus();
    }
  }, []);

  function handleChangeComment(event) {
    setContent(event.target.value.slice(0, MAX_CHARACTERS));
  }

  function handleSubmitComment(event) {
    const finalContent = content.trim();
    if (finalContent.length < MIN_CHARACTERS || finalContent.length > MAX_CHARACTERS) {
      setError("Please edit your comment length");
    } else {
      doComment(finalContent, parent_uid);
      setContent("");
    }
  }
  function handleCancelComment(event) {
    doCancel(parent_uid);
  }
  const warning = error ? error : "No images, markdown, or HTML currently supported";

  return (
    <div className="comment-form">
      <style jsx>
        {`
          textarea {
            font-size: 1.5rem;
            padding: 0.75rem;
            width: 100%;
            height: 10rem;
            margin: 0;
            border: 1px solid ${theme.borderColor};
            margin: 0.5rem 0;
            background: ${theme.backgroundColor};
            color: ${theme.primaryTextColor};
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
              "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          }
          button {
            text-align: center;
            background: ${theme.primaryButtonBackgroundColor};
            border: none;
            max-width: 15rem;
            font-size: 1.5rem;
            width: 100%;
            color: ${theme.primaryButtonTextColor};
            padding: 1rem;
            margin: 0.5rem 0;
            cursor: pointer;
          }
          .cancel {
            margin-right: 1rem;
          }
          .notes {
            display: flex;
            justify-content: space-between;
            color: ${theme.secondaryTextColor};
            margin: 0.5rem 0;
          }
          .submit-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 1rem;
          }
          .cancel {
            background: ${theme.secondaryButtonBackgroundColor};
            color: ${theme.secondaryButtonTextColor};
          }
        `}
      </style>
      <textarea
        placeholder={parentName ? `Reply to ${parentName}...` : "Have your say"}
        onChange={handleChangeComment}
        value={content}
        ref={inputRef}
      />
      <div className="notes">
        <p>{warning}</p>
        <p>
          {content.length} of {MAX_CHARACTERS}
        </p>
      </div>
      <div className="submit-container">
        {doCancel && (
          <button className="cancel" onClick={handleCancelComment}>
            Cancel
          </button>
        )}
        <button className="submit" onClick={handleSubmitComment}>
          Submit
        </button>
      </div>
    </div>
  );
};

const Comments = ({ comments, entity_type, entity_slug, signedIn, dispatch }) => {
  function handleClick(event) {
    if (!signedIn) {
      dispatch(signInByPopup());
    }
  }

  function doCancel(uid) {
    recordEvent(GA_CANCEL_COMMENT, signedIn ? GA_SIGNED_IN : GA_NOT_SIGNED_IN);
    dispatch(cancelReply({ uid }));
  }

  function doLike(uid) {
    if (signedIn) {
      recordEvent(GA_LIKE_COMMENT, GA_SIGNED_IN);
      dispatch(likeComment({ uid, entity_type, entity_slug }));
    } else {
      recordEvent(GA_UNLIKE_COMMENT, GA_NOT_SIGNED_IN);
    }
  }

  function doUnlike(uid) {
    if (signedIn) {
      recordEvent(GA_UNLIKE_COMMENT, GA_SIGNED_IN);
      dispatch(unlikeComment({ uid, entity_type, entity_slug }));
    } else {
      recordEvent(GA_UNLIKE_COMMENT, GA_NOT_SIGNED_IN);
    }
  }

  function doDelete(uid) {
    if (signedIn) {
      recordEvent(GA_DELETE_COMMENT, GA_SIGNED_IN);
      dispatch(deleteComment({ uid }));
    } else {
      recordEvent(GA_DELETE_COMMENT, GA_NOT_SIGNED_IN);
    }
  }

  function doComment(content, parent_uid = null) {
    if (signedIn) {
      recordEvent(GA_SUBMIT_COMMENT, GA_SIGNED_IN);
      dispatch(newComment({ content, parent_uid }));
    } else {
      recordEvent(GA_SUBMIT_COMMENT, GA_NOT_SIGNED_IN);
    }
  }

  function doReply(uid) {
    if (signedIn) {
      recordEvent(GA_START_COMMENT_REPLY, GA_SIGNED_IN);
      dispatch(startReply({ uid }));
    } else {
      recordEvent(GA_START_COMMENT_REPLY, GA_NOT_SIGNED_IN);
    }
  }

  return (
    <div className="comments" onClick={handleClick}>
      <style jsx>{`
        .comments {
          margin: 2rem 0;
        }
        h2 {
          margin: 1rem 0;
        }
        .comment-list {
          margin-top: 1rem;
        }
      `}</style>
      <h2>Comments</h2>
      <CommentForm doComment={doComment} />
      <div className="comment-list">
        {comments.map((comment) => (
          <div key={comment.uid} className="comment-container">
            <Comment
              key={comment.uid}
              comment={comment}
              doLike={doLike}
              doUnlike={doUnlike}
              doCancel={doCancel}
              doDelete={doDelete}
              doReply={doReply}
              doComment={doComment}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    comments: selectComments(state),
    signedIn: state.user.signedIn,
    entity_type: state.comments.entity_type,
    entity_slug: state.comments.entity_slug,
  };
};

export default connect(mapStateToProps)(Comments);
