export function action(type, ...argNames) {
  return (payload) => {
    for (let argName of argNames) {
      if (payload[argName] === undefined) {
        throw new Error(`Undefined argument '${argName}' for action ${type}`);
      }
    }
    for (let argName of Object.keys(payload || {})) {
      if (!argNames.includes(argName)) {
        throw new Error(`Unexpected argument '${argName}' for action ${type}`);
      }
    }
    return {
      ...payload,
      type: type,
    };
  };
}
