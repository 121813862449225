import * as React from "react";

function Menu() {
  const dropdowns = true;
  return (
    <ul className="menu">
      <li className="l-1">
        <a href="/platforms/windows-pc/">PC</a>
      </li>
      <li className="l-1">
        <a href="/platforms/playstation-4/">PS4</a>
      </li>
      <li className="l-1">
        <a href="/platforms/xbox-one/">XBox</a>
      </li>
      <li className="l-4">
        <a href="/platforms/nintendo-switch/">Switch</a>
      </li>
      <li className="l-3">
        <a href="/charts/top-games/">Top</a>
        {dropdowns && (
          <div className="subnav-wrapper">
            <ul className="subnav">
              <li>
                <a href="/charts/top-games/">Charts</a>
              </li>
              <li>
                <a href="/platforms/">Platforms</a>
              </li>
              <li>
                <a href="/games/">Categories</a>
              </li>
              <li>
                <a href="/reviews/">Reviews</a>
              </li>
            </ul>
          </div>
        )}
      </li>
      <li className="l-3">
        <a href="/articles/">Blog</a>
        {dropdowns && (
          <ul className="subnav">
            <li>
              <a href="/articles/news/">News</a>
            </li>
            <li>
              <a href="/reviews/">Reviews</a>
            </li>
            <li>
              <a href="/articles/upcoming/">Upcoming</a>
            </li>
          </ul>
        )}
      </li>
      <li className="l-3">
        <a href="/subscription/">Services</a>
        {dropdowns && (
          <ul className="subnav">
            <li>
              <a href="/development/">Development</a>
            </li>
            <li>
              <a href="/advertise-with-us/">Advertising</a>
            </li>
            <li>
              <a href="/subscription/">Subscription</a>
            </li>
          </ul>
        )}
      </li>
    </ul>
  );
}

export default Menu;
