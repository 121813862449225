import * as React from "react";
import { connect } from "react-redux";
import { signOut, signIn } from "../redux/user";

function AccountPage({ signedIn, dispatch }) {
  function logout() {
    dispatch(signOut());
  }
  function login() {
    dispatch(signIn());
  }

  /* Note that signedIn is a tri-state (true/false/undefined) */
  let status;
  if (signedIn === true) {
    status = (
      <>
        <h2>You are currently logged in</h2>
        <button onClick={logout} className="dark-button">
          Logout
        </button>
      </>
    );
  } else if (signedIn === false) {
    status = (
      <>
        <h2>You are logged out</h2>
        <button onClick={login} className="dark-button">
          Login
        </button>
      </>
    );
  } else {
    status = <h2>Checking for account...</h2>;
  }

  return <div className="account-page">{status}</div>;
}

function mapStateToProps(state) {
  return {
    signedIn: state.user.signedIn,
  };
}

export default connect(mapStateToProps)(AccountPage);
