import * as React from "react";

function Logo() {
  return (
    <a className="logo" href="/">
      <div className="logo-text">
        <div className="logo-name">GameSnort!</div>
        <div className="logo-tagline">Get your gaming fix.</div>
      </div>
      <svg
        className="logo-svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 50 50"
        version="1"
      >
        <use fill="none" xlinkHref="#logo" />
      </svg>
    </a>
  );
}

export default Logo;
