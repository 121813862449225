import * as Sentry from "@sentry/browser";
import { Integrations as ApmIntegrations } from "@sentry/apm";

if (process.env.NODE_ENV === "production") {
  console.log("Initializing sentry client");
  Sentry.init({
    integrations: [
      new ApmIntegrations.Tracing({ tracingOrigins: ["api.gameqik.com", "localhost", /^\//] }),
    ],
    sampleRate: 0.1,
    tracesSampleRate: 0.05,
    environment: "gamesnort-com-browser",
    dsn: "https://3da92a0dd8304f18906ee7a35c4a9e8a@o31068.ingest.sentry.io/5251829",
    ignoreErrors: [
      `UET is not defined`,
      `'geo' is not defined`,
      `ConsentString - A vendor list is required to encode a consent string`,
      `Identifier 'VIDEO_PLAYING' has already been declared`,
      `window.getPlayer is not a function`,
      `The popup has been closed by the user before finalizing the operation.`,
      `This operation has been cancelled due to another conflicting popup being opened.`,
    ],
    blacklistUrls: [/show_ads_impl/, /chrome-extension/],
  });
}
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { makeStore } from "./redux/store";

import AccountPage from "./components/AccountPage";
import ContactForm from "./components/ContactForm";
import SearchBox from "./components/SearchBox";
import Comments from "./components/Comments";
import Streamers from "./components/Streamers";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Ratings from "./components/Ratings";
import { Context } from "./context";

function initialize() {
  const dataElement = document.querySelector("#__GAMESNORT_DATA__");
  const context = JSON.parse(dataElement.innerHTML);
  const store = makeStore(context.initialStoreState);
  hydrateHeaderAndFooter(store, context);
  maybeHydrateGameElements(store);
  maybeRenderAccountPage(store);
  maybeRenderContactForm();
  maybeRenderHomepageSearch();
}

function maybeRenderContactForm() {
  const contactContainer = document.querySelector(".contact-container");
  if (contactContainer !== null) {
    ReactDOM.hydrate(<ContactForm />, contactContainer);
  }
}

function hydrateHeaderAndFooter(store, context) {
  const navContainer = document.querySelector("#nav-wrapper");
  ReactDOM.hydrate(
    <Context.Provider value={context}>
      <Provider store={store}>
        <Nav />
      </Provider>
    </Context.Provider>,
    navContainer
  );

  const footerContainer = document.querySelector("#site-footer");
  ReactDOM.hydrate(<Footer />, footerContainer);
}

function maybeRenderHomepageSearch() {
  const largeSearchContainer = document.querySelector("#large-search-container");
  if (largeSearchContainer !== null) {
    ReactDOM.render(<SearchBox />, largeSearchContainer);
  }
}

function maybeHydrateGameElements(store) {
  const ratingsContainer = document.querySelector("#game-ratings");
  if (ratingsContainer !== null) {
    ReactDOM.hydrate(
      <Provider store={store}>
        <Ratings />
      </Provider>,
      ratingsContainer
    );
  }
  const commentsContainer = document.querySelector("#comments");
  if (commentsContainer !== null) {
    ReactDOM.hydrate(
      <Provider store={store}>
        <Comments />
      </Provider>,
      commentsContainer
    );
  }
  const streamersContainer = document.querySelector("#streamers");
  if (streamersContainer !== null) {
    ReactDOM.hydrate(
      <Provider store={store}>
        <Streamers />
      </Provider>,
      streamersContainer
    );
  }
}

function maybeRenderAccountPage(store) {
  const accountContainer = document.querySelector("#account-container");
  if (accountContainer !== null) {
    ReactDOM.render(
      <Provider store={store}>
        <AccountPage />
      </Provider>,
      accountContainer
    );
  }
}

initialize();
