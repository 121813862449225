import * as React from "react";

import { AccountIcon } from "./Icons";

function Account({ dropdowns, signedIn, onToggle }) {
  const accountClassName = signedIn ? "signed-in" : "signed-out";
  return (
    <React.Fragment>
      <div className="account">
        <a onClick={onToggle}>
          <AccountIcon className={accountClassName} />
        </a>
        {!dropdowns && (
          <ul className="subnav">
            <li>
              <a href="/account/">Account</a>
            </li>
            <li>
              <a href="/subscription/">Why Join?</a>
            </li>
          </ul>
        )}
      </div>
    </React.Fragment>
  );
}

export default Account;

/*
old auth container, for now switch to pure google

<div className={classNames("overlay", { hidden })} onClick={onCancel}>
  <div className="modal">
    <CloseIcon />
    <div className="main">
      <h2 className="title">Welcome</h2>
      <div id="firebase-auth-container" />
      <p className="legal">
        <span>By continuing you indicate acceptance of our</span>&nbsp;
        <a className="link" href="/terms-and-conditions/">
          Terms of Service
        </a>
        &nbsp;
        <span>and</span>&nbsp;
        <a className="link" href="/privacy-policy/">
          Privacy Policy
        </a>
        <span>.</span>
      </p>
    </div>
    <div className="bottom">
      <a className="link support" href="/contact/">
        Contact Us For Help
      </a>
    </div>
  </div>
</div>
*/
