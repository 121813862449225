import fetch from "isomorphic-unfetch";
import * as firebase from "firebase/app";

import config from "./config";

const apiHost = config.apiHost;

export const AUTHORIZED_UIDS = {
  XTBaJKKzzJYzWWE0jOlrp1gGoH22: "James",
  pUuafjAAMaNtPpe60Dj4X37Xzp02: "Henry",
};

export async function nodePost(path, params) {
  /*
    This is to post to the 'frontend api' as oppposed to the 'backend api'.
  
    This allows caching logic for eg comments
    to be handled on the frontend, rather than at the backend API level.
    */
  const url = "/api" + path;
  const token = await firebase.auth().currentUser.getIdToken();
  const args = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ ...params, token }),
  };
  const result = await fetch(url, args);
  return await result.json();
}

export async function nodeGet(path, params) {
  /*
  
    Should only be called from the client.
  
    */
  const url = new URL("/api" + path);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  const result = await fetch(url.toString());
  if (result.status !== 200) {
    const error = new Error(`API returned ${result.status} for ${path}`);
    throw error;
  }
  return await result.json();
}

export async function apiPost(path, params) {
  /*
  
    Can be called from client or server.
  
    */
  const url = apiHost + path;
  const args = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  };
  const result = await fetch(url, args);
  return await result.json();
}

export async function apiGet(path, params = {}) {
  /*
  
    Can be called from the client or server.
  
    */
  const url = new URL(apiHost + path);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  const result = await fetch(url.toString());
  if (result.status !== 200) {
    const error = new Error(`API returned ${result.status} for ${path}`);
    Sentry.captureException(error);
    throw error;
  }
  return await result.json();
}
