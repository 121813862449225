import * as React from "react";
import axios from "axios";

import classNames from "../classnames";

const STATE = {
  UNSENT: 1,
  SENDING: 2,
  ERROR: 3,
  SENT: 4,
};

export default function ContactForm() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [agree, setAgree] = React.useState(false);
  const [submission, setSubmission] = React.useState({
    state: STATE.UNSENT,
    errors: {},
  });

  function send() {
    setSubmission({
      state: STATE.SENDING,
      errors: {},
    });
    axios
      .post("/api/contact-form/", {
        name,
        email,
        message,
        agree,
      })
      .then((res) => {
        setSubmission({
          state: STATE.SENT,
          errors: {},
        });
      })
      .catch((err) => {
        setSubmission({
          state: STATE.ERROR,
          errors: err.response.data.errors,
        });
      });
  }

  const errors = submission.errors || {};
  if (submission.state == STATE.SENT) {
    return (
      <div>
        <h2> Your message has been sent, thanks {name}! </h2>
      </div>
    );
  } else {
    const contactMessage =
      "Do you have questions about our services? Give us a call, send us an email, or ping us right here to get an answer.";

    return (
      <div className={classNames({ sending: submission.state === STATE.SENDING })}>
        <h2>Contact Us</h2>
        <p className="intro">{contactMessage}</p>
        <div className="inner-container">
          <div className="form">
            {errors.other && <p className="error"> {errors.other} </p>}
            <div className="form-pair small">
              {errors.name && <p className="error"> {errors.name} </p>}
              <input
                type="text"
                value={name}
                placeholder={"Your name..."}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-pair small">
              {errors.email && <p className="error"> {errors.email} </p>}
              <input
                type="email"
                value={email}
                placeholder={"Your email..."}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-pair big">
              {errors.message && <p className="error"> {errors.message} </p>}
              <textarea
                name="message"
                id="message"
                value={message}
                placeholder={"Your message..."}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="form-pair check">
              {errors.agree && <p className="error"> {errors.agree} </p>}
              <div className="check-pair">
                <input
                  type="checkbox"
                  name="agree"
                  id="agree"
                  checked={agree}
                  onChange={() => setAgree(!agree)}
                />
                <label htmlFor="agree">
                  I have read and agree to the&nbsp;
                  <a className="link" href="/privacy-policy/">
                    Privacy Policy
                  </a>
                </label>
              </div>
            </div>
            <div className="form-pair">
              <button
                type="button"
                className="dark-button"
                value="Send Message"
                onClick={() => send()}
              >
                Send Message
              </button>
            </div>
          </div>
          <div className="details">
            <h3 className="title">Contact Information</h3>
            <address>
              <pre>
                {`GameSnort Games
539 Herald St
Victoria BC  V8W 1S5
Canada
GameSnort.com`}
              </pre>
              <a className="link" href={`mailto:info@gamesnort.com`}>
                info@gamesnort.com
              </a>
              <br />
            </address>
          </div>
        </div>
      </div>
    );
  }
}
