import * as React from "react";

import { connect } from "react-redux";

import classNames from "../classnames";
import { signIn, signOut } from "../redux/user";
import Logo from "./Logo";
import Menu from "./Menu";
import Account from "./Account";
import ExpandableMenu from "./ExpandableMenu";
import NavSearch from "./NavSearch";

function Nav({ signedIn, signingIn, dispatch }) {
  const [searching, setSearching] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  function onToggleAccount() {
    setSearching(false);
    setMenuOpen(false);
    if (signedIn) {
      window.location.href = "/account/";
    } else {
      dispatch(signIn());
    }
  }

  function onCancelAccount() {
    dispatch(signOut());
  }

  function onOpenSearch() {
    setSearching(true);
  }

  function onCloseSearch() {
    setSearching(false);
  }

  function onOpenMenu() {
    setMenuOpen(true);
  }
  function onCloseMenu() {
    setMenuOpen(false);
  }

  const dropdowns = true;

  return (
    <div id="nav" className={classNames({ searching })}>
      <Logo />
      <NavSearch open={searching} onOpen={onOpenSearch} onClose={onCloseSearch} />
      <Menu />
      <Account
        signedIn={signedIn}
        dropdowns={dropdowns}
        onCancel={onCancelAccount}
        onToggle={onToggleAccount}
      />
      <ExpandableMenu open={menuOpen} onOpen={onOpenMenu} onClose={onCloseMenu} />
      {searching && <div className="overlay" />}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    signedIn: state.user.signedIn,
    signingIn: state.user.signingIn,
  };
}

export default connect(mapStateToProps)(Nav);
