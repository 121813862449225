import * as React from "react";
import { connect } from "react-redux";
import {
  rateGame,
  likeGame,
  unlikeGame,
  favoriteGame,
  unfavoriteGame,
  signIn,
} from "../redux/user";
import { UserIcon, LogoIcon, LikeIcon, FavoriteIcon } from "./Icons";
import Stars from "./Stars";
import classNames from "../classnames";

function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

function recordEvent(action, label, value) {
  gtag("event", action, { event_category: "game_feedback", event_label: label, value });
}

const GA_RATE_GAME = "Rate game";
const GA_LIKE_GAME = "Like game";
const GA_UNLIKE_GAME = "Unlike game";
const GA_FAVORITE_GAME = "Favorite game";
const GA_UNFAVORITE_GAME = "Unfavorite game";

function Ratings({ slug, signedIn, site_rating, user_rating, dispatch, liked, favorited }) {
  function onSetRating(rating) {
    if (!signedIn) {
      dispatch(signIn());
    } else {
      recordEvent(GA_RATE_GAME, name, parseInt(rating));
      dispatch(rateGame({ slug, rating }));
    }
  }
  function onClickLike() {
    if (!signedIn) {
      dispatch(signIn());
    } else if (liked) {
      recordEvent(GA_UNLIKE_GAME, name);
      dispatch(unlikeGame({ slug }));
    } else {
      recordEvent(GA_LIKE_GAME, name);
      dispatch(likeGame({ slug }));
    }
  }
  function onClickFavorite() {
    if (!signedIn) {
      dispatch(signIn());
    } else if (favorited) {
      recordEvent(GA_UNFAVORITE_GAME, name);
      dispatch(unfavoriteGame({ slug }));
    } else {
      recordEvent(GA_FAVORITE_GAME, name);
      dispatch(favoriteGame({ slug }));
    }
  }
  return (
    <div className="ratings">
      <div className="user-ratings">
        <UserIcon />
        <Stars namespace="game" slug={slug} setRating={onSetRating} rating={user_rating} />
      </div>
      <div className="site-ratings">
        <LogoIcon />
        <span className="site-rating-value">{site_rating}</span>
      </div>
      <div className={classNames("user-likes", { liked })} onClick={onClickLike}>
        <LikeIcon />
        <span className="label">Like</span>
      </div>
      <div className={classNames("user-favorites", { favorited })} onClick={onClickFavorite}>
        <FavoriteIcon />
        <span className="label">Favorite</span>
      </div>
    </div>
  );
}

function mapStateToProps({ user, game }) {
  const data = {
    signedIn: user.signedIn,
    site_rating: game.site_rating,
    user_rating: game.user_rating,
    slug: game.slug,
  };
  if (user.profileLoaded) {
    const { ratings, likes, favorites } = user;
    data.user_rating =
      ratings.games[game.slug] !== undefined ? ratings.games[game.slug] : game.user_rating;
    data.favorited = favorites.games.includes(game.slug);
    data.liked = likes.games.includes(game.slug);
  }
  return data;
}

export default connect(mapStateToProps)(Ratings);
