import * as React from "react";


export default function Stars({ namespace, slug, rating, setRating }) {
  const [visibleRating, setVisibleRating] = React.useState(null);
  const starsRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const id = `stars_mask_${namespace}_${slug}`;
  const boxWidth = 535;
  const boxHeight = 110;
  const maxRating = 5;
  const currentRating = visibleRating === null ? rating : visibleRating;
  const maskWidth = (boxWidth / maxRating) * currentRating;

  function handleMouseLeave(event) {
    if (!setRating) {
      return;
    }
    setVisibleRating(rating);
  }
  function handleMouseMove(event) {
    if (!setRating || starsRef.current === null) {
      return;
    }
    const x = event.clientX - starsRef.current.getBoundingClientRect().left;
    const r = (x / starsRef.current.clientWidth) * maxRating;
    setVisibleRating(r);
  }
  function handleClick(event) {
    if (!setRating || starsRef.current === null) {
      return;
    }
    const x = event.clientX - starsRef.current.getBoundingClientRect().left;
    const r = (x / starsRef.current.clientWidth) * maxRating;
    setRating(r);
  }
  return (
    <svg
      className={"stars"}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${boxWidth} ${boxHeight}`}
      version="1"
      ref={starsRef}
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <input type="text" ref={inputRef} />
      <mask id={id}>
        <rect x="0" y="0" width={maskWidth} height="110" fill="white" />
      </mask>
      <use fill="none" stroke="#666" xlinkHref="#star-paths" />
      <use fill="url(#star-radial-gradient)" xlinkHref="#star-paths" mask={`url(#${id})`} />
    </svg>
  );
}
