import * as React from "react";
import SearchBox from "./SearchBox";

import { CloseIcon, SearchIcon } from "./Icons";

export default function NavSearch({ open, onOpen, onClose }) {
  function onToggleSearch() {
    if (open) {
      onClose();
    } else {
      onOpen();
    }
  }

  function onFocus(event) {
    onOpen();
  }

  function onBlur(event) {
    onClose();
  }
  return (
    <React.Fragment>
      <div className="search-container">
        <SearchBox id="nav-search" onFocus={onFocus} onBlur={onBlur} focus={open} />
      </div>
      <div className="search-toggle toggle" onClick={onToggleSearch}>
        {open ? <CloseIcon /> : <SearchIcon />}
      </div>
    </React.Fragment>
  );
}
