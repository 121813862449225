import * as React from "react";
import Autosuggest from "react-autosuggest";
import axios from "axios";

import Stars from "./Stars";
import { SearchIcon } from "./Icons";

const theme = {
  container: {
    position: "relative",
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    width: "100%",
    border: "1px solid #eee",
    backgroundColor: "#fff",
    fontWeight: 300,
    zIndex: 200,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    color: "#333",
  },
  suggestion: {
    cursor: "pointer",
    padding: "8px 8px",
    borderBottom: "1px solid #eee",
  },
  suggestionHighlighted: {
    backgroundColor: "#ddd",
  },
};

function getParams(qs) {
  if (!qs) {
    return {};
  }
  var vars = qs.split("&");
  var result = {};
  for (var i = 0; i < vars.length; i++) {
    var key, value;
    var equals = vars[i].indexOf("=");
    if (equals == -1) {
      key = decodeURIComponent(vars[i]);
      value = "";
    } else {
      key = decodeURIComponent(vars[i].substring(0, equals));
      value = decodeURIComponent(vars[i].substring(equals + 1));
    }
    result[key] = value;
  }
  return result;
}

export function cloudflareImage(path, params = {}) {
  if (path.startsWith("/")) {
    path = path.slice(1);
  }
  if (Object.keys(params).length === 0) {
    return `https://cdn.gamesnort.com/${path}`;
  }
  const options = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join(",");
  return `https://cdn.gamesnort.com/cdn-cgi/image/${options}/${path}`;
}

export default function SearchBox({ id, onFocus, onBlur, focus }) {
  let q = "";
  if (typeof window !== "undefined") {
    const params = getParams(window.location.search.substring(1));
    q = params.q || "";
  }
  const [value, setValue] = React.useState(q);
  const [suggestions, setSuggestions] = React.useState([]);
  const formRef = React.useRef(null);
  const inputRef = React.useRef(null);

  function onChange(event, { newValue }) {
    setValue(newValue);
  }

  React.useEffect(() => {
    if (inputRef.current && inputRef.current.input && focus) {
      inputRef.current.input.focus();
    }
  });

  function onSuggestionsFetchRequested({ value }) {
    const params = { q: value };
    const url = `/api/autosuggest/`;
    axios.get(url, { params }).then((response) => {
      const data = response.data;
      const { suggestions } = data;
      setSuggestions(suggestions);
    });
  }

  function getSuggestionValue(suggestion) {
    return suggestion.name;
  }

  function renderSuggestion(suggestion) {
    return (
      <div className="autosuggest-option">
        <img src={cloudflareImage(suggestion.image, { width: 44, height: 44, fit: "crop" })} />
        <span>{suggestion.name}</span>
        <Stars
          key={suggestion.slug}
          slug={suggestion.slug}
          namespace={"autosuggest"}
          rating={suggestion.user_rating}
        />
      </div>
    );
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSuggestionSelected(event, data) {
    event.preventDefault();
    window.location.href = data.suggestion.url;
  }

  function handleMouseDownSearch(event) {
    if (formRef.current && value && value.trim()) {
      formRef.current.submit();
    }
  }

  const inputProps = {
    placeholder: "Search...",
    name: "q",
    className: "search-input",
    value,
    onChange,
    onFocus,
    onBlur,
  };
  return (
    <form method="get" action="/search/" ref={formRef}>
      <Autosuggest
        id={id}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={theme}
        highlightFirstSuggestion={false}
        onSuggestionSelected={onSuggestionSelected}
        ref={inputRef}
      />
      <SearchIcon onMouseDown={handleMouseDownSearch} />
    </form>
  );
}

// focusInputOnSuggestionClick =  !isMobile.any
// focusInputOnSuggestionClick={true}
