import { all, call, take, takeEvery, put, spawn, select, getContext } from "redux-saga/effects";
import { eventChannel } from "redux-saga";

import * as firebase from "firebase/app";
import "firebase/auth";

import { apiPost, nodePost } from "../api";
import { action } from "./actions";
import { LIKE_COMMENT, UNLIKE_COMMENT } from "./comments";

export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_BY_POPUP = "SIGN_IN_BY_POPUP";
export const SIGN_IN_BY_REDIRECT = "SIGN_IN_BY_REDIRECT";
export const SIGN_OUT = "SIGN_OUT";

export const SET_PROFILE = "SET_PROFILE";

// These actions are precipitated by firebase (external source)
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";

export const RATE_GAME = "RATE_GAME";
export const LIKE_GAME = "LIKE_GAME";
export const UNLIKE_GAME = "UNLIKE_GAME";
export const FAVORITE_GAME = "FAVORITE_GAME";
export const UNFAVORITE_GAME = "UNFAVORITE_GAME";

export const signIn = action(SIGN_IN);
export const signInByRedirect = action(SIGN_IN_BY_REDIRECT);
export const signInByPopup = action(SIGN_IN_BY_POPUP);
export const signOut = action(SIGN_OUT);
export const loggedIn = action(LOGGED_IN, "name", "email", "uid", "token");
export const loggedOut = action(LOGGED_OUT);
export const setProfile = action(SET_PROFILE, "profile");
export const rateGame = action(RATE_GAME, "slug", "rating");
export const likeGame = action(LIKE_GAME, "slug");
export const unlikeGame = action(UNLIKE_GAME, "slug");
export const favoriteGame = action(FAVORITE_GAME, "slug");
export const unfavoriteGame = action(UNFAVORITE_GAME, "slug");

export function userReducer(state = {}, action) {
  let key;
  switch (action.type) {
    case LIKE_GAME:
      return {
        ...state,
        likes: {
          ...state.likes,
          games: state.likes.games.filter((slug) => slug !== action.slug).concat(action.slug),
        },
      };
    case UNLIKE_GAME:
      return {
        ...state,
        likes: {
          ...state.likes,
          games: state.likes.games.filter((slug) => slug !== action.slug),
        },
      };
    case FAVORITE_GAME:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          games: state.favorites.games.filter((slug) => slug !== action.slug).concat(action.slug),
        },
      };
    case UNFAVORITE_GAME:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          games: state.favorites.games.filter((slug) => slug !== action.slug),
        },
      };
    case RATE_GAME:
      return {
        ...state,
        ratings: {
          ...state.ratings,
          games: {
            ...state.ratings.games,
            [action.slug]: action.rating,
          },
        },
      };
    case LIKE_COMMENT:
      key = `${action.entity_type}_comments`;
      return {
        ...state,
        likes: {
          ...state.likes,
          [key]: state.likes[key].filter((uid) => uid !== action.uid).concat(action.uid),
        },
      };
    case UNLIKE_COMMENT:
      key = `${action.entity_type}_comments`;
      return {
        ...state,
        likes: {
          ...state.likes,
          [key]: state.likes[key].filter((uid) => uid !== action.uid),
        },
      };
    case LOGGED_IN:
      return {
        ...state,
        name: action.name,
        email: action.email,
        uid: action.uid,
        signedIn: true,
        signingIn: false,
      };
    case SET_PROFILE:
      return {
        ...state,
        ...action.profile,
        profileLoaded: true,
      };
    case LOGGED_OUT:
      return {
        ...state,
        signedIn: false,
        signingIn: false,
        profileLoaded: false,
      };
    case SIGN_IN:
      return {
        ...state,
        signingIn: true,
      };
    case SIGN_IN_BY_REDIRECT:
      return {
        ...state,
        signingIn: true,
      };
    case SIGN_IN_BY_POPUP:
      return {
        ...state,
        signingIn: true,
      };
    case SIGN_OUT:
      return {
        signedIn: false,
        signingIn: false,
        profileLoaded: false,
      };
    default:
      return state;
  }
}

function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

function recordEvent(action, label, value) {
  gtag("event", action, { event_category: "user", event_label: label, value });
}

const GA_SIGN_IN = "Sign in";
const GA_CREATE_ACCOUNT = "Create account";
const GA_SIGN_OUT = "Sign out";

function* doRateGame(action) {
  const { slug, rating } = action;
  yield nodePost("/user/rate", {
    entity_type: "game",
    entity_field: "slug",
    entity_value: slug,
    rating,
  });
}

function* doFavoriteGame(action) {
  const { slug } = action;
  yield nodePost("/user/favorite", {
    entity_type: "game",
    entity_field: "slug",
    entity_value: slug,
  });
}

function* doUnfavoriteGame(action) {
  const { slug } = action;
  yield nodePost("/user/unfavorite", {
    entity_type: "game",
    entity_field: "slug",
    entity_value: slug,
  });
}

function* doLikeGame(action) {
  const { slug } = action;
  yield nodePost("/user/like", {
    entity_type: "game",
    entity_field: "slug",
    entity_value: slug,
  });
}

function* doUnlikeGame(action) {
  const { slug } = action;
  yield nodePost("/user/unlike", {
    entity_type: "game",
    entity_field: "slug",
    entity_value: slug,
  });
}

function* doLikeComment(action) {
  const { comments } = yield select();
  const { entity_type, entity_slug } = comments;
  yield nodePost("/user/comments/like", {
    comment_uid: action.uid,
    entity_slug,
    entity_type,
  });
}

function* doUnlikeComment(action) {
  const { comments } = yield select();
  const { entity_type, entity_slug } = comments;
  yield nodePost("/user/comments/unlike", {
    comment_uid: action.uid,
    entity_slug,
    entity_type,
  });
}

function* doSignOut() {
  recordEvent(GA_SIGN_OUT);
  firebase.auth().signOut();
}

function* doSignInByRedirect() {
  recordEvent(GA_SIGN_IN);
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider);
}

function* doSignInByPopup() {
  recordEvent(GA_SIGN_IN);
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(provider);
}

function makeAuthChannel() {
  // Generates actions that can be dispatched into the store.
  return eventChannel((emitter) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        emitter(
          loggedIn({
            name: user.displayName,
            email: user.email,
            uid: user.uid,
            token: await user.getIdToken(),
          })
        );
      } else {
        emitter(loggedOut());
      }
    });
    return () => {};
  });
}

function* doWatchAuth() {
  const authChannel = yield call(makeAuthChannel);
  while (true) {
    const action = yield take(authChannel);
    yield put(action);
  }
}

function* doLoadUser({ name, email, token }) {
  const { profile, created } = yield apiPost("/user/login/", { name, email, token });
  if (created) {
    recordEvent(GA_CREATE_ACCOUNT);
  }
  yield put(setProfile({ profile }));
}

export function loadFirebase(firebaseConfig) {
  try {
    firebase.initializeApp(firebaseConfig);
  } catch (error) {
    if (!/app\/duplicate-app/.test(error.message)) {
      console.error("Firebase initialization error", error);
      throw error;
    }
  }
}

const isServer = typeof window === "undefined";

export function* userSaga() {
  if (!isServer) {
    const firebaseConfig = yield getContext("firebaseConfig");
    loadFirebase(firebaseConfig);
    yield spawn(doWatchAuth);
  }
  yield all([
    takeEvery(LOGGED_IN, doLoadUser),
    takeEvery(SIGN_IN, doSignInByPopup),
    takeEvery(SIGN_IN_BY_POPUP, doSignInByPopup),
    takeEvery(SIGN_IN_BY_REDIRECT, doSignInByRedirect),
    takeEvery(SIGN_OUT, doSignOut),
    takeEvery(LIKE_GAME, doLikeGame),
    takeEvery(UNLIKE_GAME, doUnlikeGame),
    takeEvery(LIKE_COMMENT, doLikeComment),
    takeEvery(UNLIKE_COMMENT, doUnlikeComment),
    takeEvery(RATE_GAME, doRateGame),
    takeEvery(FAVORITE_GAME, doFavoriteGame),
    takeEvery(UNFAVORITE_GAME, doUnfavoriteGame),
  ]);
}
