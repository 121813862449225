export const theme = {
  name: "Light",
  emphasisTextColor: "#333",
  primaryTextColor: "#555",
  secondaryTextColor: "#777",
  tertiaryTextColor: "#aaa",
  borderColor: "#ddd",
  primaryIconColor: "#666",
  secondaryIconColor: "#aaa",
  backgroundColor: "#fff",
  selectedBackgroundColor: "#eee",
  modalBackgroundColor: "rgba(0, 0, 0, .9)",
  primaryButtonBackgroundColor: "#ea596e",
  primaryButtonTextColor: "#fff",
  secondaryButtonBackgroundColor: "#aaa",
  secondaryButtonTextColor: "#eee",
  buttonBorderColor: "#eee",
  logoLightningColor: "#7a7a7a",
  logoTextColor: "#555",
  accentTextColor: "#ea596e",
};
