import * as React from "react";
import axios from "axios";

const STATE = {
  UNSENT: 1,
  SENDING: 2,
  ERROR: 3,
  SENT: 4
};

export default function Newsletter() {
  const [email, setEmail] = React.useState("");
  const [submission, setSubmission] = React.useState({
    state: STATE.UNSENT,
    error: null
  });

  function handleSubmit(event) {
    event.preventDefault();
    setSubmission({
      state: STATE.SENDING,
      error: null
    });
    axios
      .post("/api/newsletter-form/", {
        email
      })
      .then(res => {
        setSubmission({
          state: STATE.SENT,
          error: null
        });
      })
      .catch(err => {
        setSubmission({
          state: STATE.ERROR,
          error: err.response.data.error
        });
      });
  }
  if (submission.state == STATE.SENT) {
    return (
      <div className="newsletter-container">
        <h3 className="title">Your subscription request has been received, thank you!</h3>
      </div>
    );
  } else {
    return (
      <div className="newsletter-container">
        <h3 className="title">
          Subscribe now for <strong>weekly updates</strong> - worth a snort!
        </h3>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter your email"
          />
          <button className="light-button" onMouseDown={handleSubmit}>
            Subscribe now
          </button>
        </form>
      </div>
    );
  }
}
