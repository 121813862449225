const developmentConfig = {
  apiHost: "http://localhost:8000",
};

const productionConfig = {
  sentryDSN: "https://478d1aaea8364480b946bef72cc264cd@o31068.ingest.sentry.io/5239723", // gamesnort-client
  apiHost: "https://api.gamesnort.com",
};

export const firebaseConfig = {
  apiKey: "AIzaSyBDoCXmRwCeDGqaBD4ylj9IE3JsCHQSWCA",
  authDomain: "gamesnort-e7d87.firebaseapp.com",
  databaseURL: "https://gamesnort-e7d87.firebaseio.com",
  projectId: "gamesnort-e7d87",
  storageBucket: "gamesnort-e7d87.appspot.com",
  messagingSenderId: "266444585416",
  appId: "1:266444585416:web:c652eca98529ef81bf68fd",
};

export default {
  firebaseConfig,
  ...(process.env.NODE_ENV === "production" ? productionConfig : developmentConfig),
};
