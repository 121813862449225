import { action } from "./actions";

const initialGame = {
  user_rating: 0,
  site_rating: 0,
  slug: "",
  name: "",
};

export const SET_GAME = "SET_GAME";

export const setGame = action(SET_GAME, "game");

export function gameReducer(state = initialGame, action) {
  switch (action.type) {
    case SET_GAME:
      return action.game;
    default:
      return state;
  }
}
