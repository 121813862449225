import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import reducer from "./reducers";
import sagas from "./sagas";
import config from "../config";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export function makeStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware({
    context: { firebaseConfig: config.firebaseConfig },
  });
  const middleware = [sagaMiddleware];
  const enhancer = composeEnhancers(
    applyMiddleware(...middleware)
    // other store enhancers if any
  );

  const store = createStore(reducer, initialState, enhancer);
  sagaMiddleware.run(sagas);
  return store;
}
