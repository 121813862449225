import * as React from "react";
import classNames from "../classnames";
import Logo from "./Logo";
import links from "../links";

import { RightArrow } from "./Icons";
import Newsletter from "./Newsletter";

function Social() {
  return (
    <div className="social">
      <div className="social-message">Follow Us</div>
      <a href="https://twitter.com/GameSnort" target="_blank">
        <svg
          className="social-icon"
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
          />
        </svg>
      </a>
      <a href="https://www.facebook.com/gamesnort/" target="_blank">
        <svg
          className="social-icon"
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 264 512"
        >
          <path
            fill="currentColor"
            d="M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z"
          />
        </svg>
      </a>
    </div>
  );
}

function FooterLinks({ title, links }) {
  const [expanded, setExpanded] = React.useState(false);
  function handleClick() {
    setExpanded(!expanded);
  }
  return (
    <div className="link-group">
      <h4 className={classNames("link-header", { expanded })} onClick={handleClick}>
        {title}
        <RightArrow />
      </h4>
      <ul className={classNames("links", { expanded })}>
        {links.map((link) => (
          <li key={link.url}>
            <a href={link.url}>{link.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

function TopFooter() {
  return (
    <div className="top-footer">
      <div className="information">
        <h4 className="title">Information</h4>
        <p className="description">
          GameSnort is your best location for curated video games, reveals, exclusive content, and
          in-depth reviews. However, it is important (so our lawyers tell us) that you realize we
          are *not affiliated* with any video game developer unless we specifically state otherwise.
          We do not host or provide any video game files or downloads. Thanks for your
          understanding.
        </p>
      </div>
      {links.footerLinks.map((entry) => (
        <FooterLinks key={entry.title} title={entry.title} links={entry.links} />
      ))}
    </div>
  );
}

function LogoAttribution() {
  return (
    <div className="logo-attribution">
      <span>Logo based on icon by user</span>{" "}
      <a href="https://iconscout.com/contributors/twitter-inc">Twitter Emoji</a>
    </div>
  );
}

function BottomFooter() {
  return (
    <div className="bottom-footer">
      <Logo />
      <Social />
      <Copyright />
      <LogoAttribution />
    </div>
  );
}

function Copyright() {
  return (
    <div className="copyright">© 2020 GameSnort.com - Paid Game Critics - All Rights Reserved.</div>
  );
}

export default function Footer() {
  return (
    <>
      <Newsletter />
      <TopFooter />
      <BottomFooter />
    </>
  );
}
