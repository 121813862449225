import * as React from "react";
import links from "../links";
import { Context } from "../context";

import { CloseIcon, MenuIcon } from "./Icons";

export default function ExpandableMenu({ open, onOpen, onClose }) {
  const { site } = React.useContext(Context);
  function onToggleMenu(event) {
    if (open) {
      onClose(event);
    } else {
      onOpen(event);
    }
  }
  return (
    <React.Fragment>
      <div className="menu-toggle toggle" onClick={onToggleMenu}>
        {open ? <CloseIcon /> : <MenuIcon />}
      </div>
      {open && (
        <div className="overlay" onClick={onClose}>
          <div className="expanded-menu">
            <ul className="row">
              {site.platforms.map(({ name, slug }, i) => (
                <li key={i}>
                  <a href={`/platforms/${slug}/`}>{name}</a>
                </li>
              ))}
            </ul>
            <ul className="column">
              {links.expandedLinks.map(({ name, url }, i) => (
                <li key={i}>
                  <a href={url}>{name}</a>
                </li>
              ))}
            </ul>
            <ul className="row">
              {site.categories.map(({ name, slug }, i) => (
                <li key={i}>
                  <a href={`/games/${slug}/`}>{name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
